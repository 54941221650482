import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0f2d956a&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=0f2d956a&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f2d956a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CkIcon: require('/Users/nadavkatan/Documents/Aaltra/daikin_collectivehousing/vue/components/ck/Icon/index.js').default,CkSmallLoader: require('/Users/nadavkatan/Documents/Aaltra/daikin_collectivehousing/vue/components/ck/SmallLoader.vue').default,ButtonColor: require('/Users/nadavkatan/Documents/Aaltra/daikin_collectivehousing/vue/components/styledcomponents/ButtonColor.js').default,CkButtonLink: require('/Users/nadavkatan/Documents/Aaltra/daikin_collectivehousing/vue/components/ck/Button/ButtonLink.vue').default})
