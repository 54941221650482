
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'right',
    },
    hover: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isHovering: false,
    }
  },
  computed: {
    active() {
      return this.value || (this.hover && this.isHovering)
    },
  },
  methods: {
    closePopover() {
      this.$emit('input', false)
    },
  },
}
