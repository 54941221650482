
export default {
  props: {
    title: [String, Array],
    subtitle: String,
    id: String,
    icon: String,
    value: [String, Boolean, Array],
    val: [String, Boolean],
    small: Boolean,
    semiselect: Boolean,
    disabled: Boolean,
    disabledReason: String,
  },
  data() {
    return {
      localChecked: false,
    }
  },
  computed: {
    isChecked() {
      if (typeof this.value === 'string') {
        if (this.value === this.val) {
          return true
        }
      } else if (typeof this.value === 'boolean') {
        return this.value
      } else {
        return this.value.includes(this.val)
      }
      return false
    },
    localId() {
      if (this.id) {
        return `${this.id}`
      }
      return 'checkbox'
    },
    checked: {
      get() {
        return this.value
      },
      set(val) {
        this.localChecked = val
      },
    },
  },
  methods: {
    onChange(ev) {
      this.$emit('input', { id: this.localId, checked: this.localChecked })
    },
  },
}
